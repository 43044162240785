const Geolocation = {};

Geolocation.getUserLocation = (success, error) => {
    if(!success) return;
    if (!navigator.geolocation) return error('Geolocation not supported');

    navigator.geolocation.getCurrentPosition((position) => {
        success({lat: +position.coords.latitude, lng: position.coords.longitude})
    }, (err) => {
        if(error) error(err);
    });
    
}

export default Geolocation;