import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import GoogleMap from '../components/GoogleMap/GoogleMap';
import { BrandContext } from '../context/BrandContext/context';
import GoogleSearchBox from '../components/GoogleAutocomplete/GoogleSearchBox';
import GoogleAutocompleteWidget from '../components/GoogleAutocomplete/GoogleAutocompleteWidget';
import OverlayLayerSelector from '../components/Overlay/OverlayLayerSelector';
import WMSOverlay from '../services/WMSOverlay'
import Utils from '../utils/Utils';
import Geolocation from '../services/Geolocation';
import LayerProviderListContent from '../components/MarkerContent/LayerProviderListContent';
import { SiteContext } from '../context/SiteContext/context';
import fetchJsonp from 'fetch-jsonp';
import DrawLine from '../services/DrawLine';
// require('es6-promise').polyfill();

let GoogleDefaultInfoWindow = require('../utils/GoogleDefaultInfoWindow');
const ReactDOMServer = require('react-dom/server');

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%'
    },
    rootNav: {
        display: 'flex',
        height: '100%'
    },
    googleMaps: {
        position: 'absolute'
    },
    userControls: {
        position: 'absolute',
        zIndex: '1000',
        width: '100%',
        maxWidth: '500px'
    },
    layerControls: {
        width: '100%',
        maxWidth: '400px',
        paddingTop: '2pt'
    }
}));


export default function CoverageMapContainer(props) {
    const { brand } = useContext(BrandContext)
    const { settings } = useContext(SiteContext)
    const classes = useStyles();
    // React.useLayoutEffect = React.useEffect 
    const [disableGeolocation, setDisableGeolocation] = useState(false);
    const [searchingLocation, setSearchingLocation] = useState(true);
    const markerRef = useRef(); //useState nod updating as component is not rendering at this stage
    const mapRef = useRef(); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
    const googleGeocoderRef = useRef(); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
    const popupRef = useRef(); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
    const coverageResultsRef = useRef({}); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
    const googlePlaceRef = useRef(); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
    const googlePolylinesRef = useRef([]); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
    const [singleLayerSelect, setSingleSelect] = useState(false)
    const [place, setPlace] = React.useState();
    const [layers, setLayers] = React.useState([

        {
            title: 'Skywire',
            layerName: 'skywire', //geoserver
            layerService: 'microwave', //service type
            layerCoverageName: 'skywire', //value to check for valid coverage
            layerServiceName: 'High Sites',
            layerStyle: '',
            layerType: '',
            display: true,
            active: true, 
            showLocation: true,
            hideLive:true,
            hideComingSoon: true,
            noLegend:true
        },
        {
            title: 'MTN',
            layerName: 'mtnsi:MTNSA-Coverage-FIXLTE-0',
            layerService: 'lte',
            layerServiceName: 'LTE',
            layerCoverageName: 'mtn',
            layerStyle: 'blue',
            layerType: '',
            customSLD:
                '%3CStyledLayerDescriptor%20xmlns%3Asld%3D%22http%3A%2F%2Fwww.opengis.net%2Fsld%22%20version%3D%221.0.0%22%20xsi%3AschemaLocation%3D%22http%3A%2F%2Fwww.opengis.net%2Fsld%20http%3A%2F%2Fschemas.opengis.net%2Fsld%2F1.0.0%2FStyledLayerDescriptor.xsd%22%20xmlns%3Axsi%3D%22http%3A%2F%2Fwww.w3.org%2F2001%2FXMLSchema-instance%22%20xmlns%3Aogc%3D%22http%3A%2F%2Fwww.opengis.net%2Fogc%22%20xmlns%3Agml%3D%22http%3A%2F%2Fwww.opengis.net%2Fgml%22%3E%0A%3CNamedLayer%3E%0A%3CName%3Emtnsi%3AMTNSA-Coverage-FIXLTE-0%3C%2FName%3E%0A%3CUserStyle%3E%0A%3CName%3E1%3C%2FName%3E%0A%3CFeatureTypeStyle%3E%0A%3CRule%3E%0A%3CPolygonSymbolizer%3E%0A%3CFill%3E%0A%3CCssParameter%20name%3D%22fill%22%3E%23fc4a2a%3C%2FCssParameter%3E%0A%3CCssParameter%20name%3D%22fill-opacity%22%3E1%3C%2FCssParameter%3E%0A%3C%2FFill%3E%0A%3CStroke%3E%0A%3CCssParameter%20name%3D%22stroke%22%3E%23fc4a2a%3C%2FCssParameter%3E%0A%3CCssParameter%20name%3D%22stroke-width%22%3E0.5%3C%2FCssParameter%3E%0A%3C%2FStroke%3E%0A%3C%2FPolygonSymbolizer%3E%0A%3C%2FRule%3E%0A%3C%2FFeatureTypeStyle%3E%0A%3C%2FUserStyle%3E%0A%3C%2FNamedLayer%3E%0A%3C%2FStyledLayerDescriptor%3E%0A',
            display: true,
            active: false,
            externalWMS: 'mtn',
            externalWMSURL: 'https://mtnsi.mtn.co.za/cache/geoserver/wms',
            liveStatus: {
                color: '#fc4a2a',
                contrastText: '#ffffff'
            },
            hideComingSoon: true
            // comingSoonStatus: {
            //     color: '#6799fc',
            //     contrastText: '#ffffff'
            // }
        },
        {
            title: 'Telkom',
            layerName: 'lte',
            layerService: 'lte',
            layerServiceName: 'LTE',
            layerCoverageName: 'telkom',
            layerStyle: '',
            layerType: '',
            display: true,
            active: false,
            liveStatus: {
                color: '#E59310',
                contrastText: '#ffffff'
            },
            hideComingSoon: true
            // comingSoonStatus: {
            //     color: '#EFC47F',
            //     contrastText: '#ffffff'
            // }
        },
        {
            title: 'Openserve',
            layerName: 'openserve', //geoserver
            layerService: 'businessfibre', //service type
            layerCoverageName: 'openserve', //value to check for valid coverage
            layerServiceName: 'FTTB',
            layerStyle: '',
            layerType: '',
            display: true,
            active: false,
            liveStatus: {
                color: '#90288C',
                contrastText: '#ffffff'
            },
            comingSoonStatus: {
                color: '#B272AE',
                contrastText: '#ffffff'
            }
        },
        {
            title: 'Frogfoot',
            layerName: 'frogfoot', //geoserver
            layerService: 'businessfibre', //service type
            layerCoverageName: 'frogfoot', //value to check for valid coverage
            layerServiceName: 'FTTB',
            layerStyle: '',
            layerType: '',
            display: true,
            active: false,
            liveStatus: {
                color: '#00E608',
                contrastText: '#000000'
            },
            comingSoonStatus: {
                color: '#90F994',
                contrastText: '#000000'
            }
        },
        {
            title: 'Metrofibre Networks',
            layerName: 'metrofibre', //geoserver
            layerService: 'businessfibre', //service type
            layerCoverageName: ['metrofibre','metrofibrebuildings'], //value to check for valid coverage
            layerServiceName: 'FTTB',
            layerStyle: '',
            layerType: '',
            display: true,
            active: false,
            liveStatus: {
                color: '#00FFED',
                contrastText: '#000000'
            },
            hideComingSoon: true
            // comingSoonStatus: {
            //     color: '#9BF7EF',
            //     contrastText: '#000000'
            // }
        }, 
        {
            title: 'DFA',
            layerName: 'dfa', //geoserver
            layerService: 'businessfibre', //service type
            layerCoverageName: ['dfa','dfaductbank'], //value to check for valid coverage
            layerServiceName: 'FTTB',
            layerStyle: '',
            layerType: '',
            display: true,
            active: false,
            liveStatus: {
                color: '#4286F4',
                contrastText: '#ffffff'
            },
            comingSoonStatus: {
                color: '#96BBF7',
                contrastText: '#ffffff'
            }
        }, 
    ]);

    const [googleMapOptions, setGoogleMapOptions] = React.useState({
        map: {
            center: brand.googlemaps.center ? brand.googlemaps.center : { lat: -26, lng: 25 },
            zoom: 6,
            minZoom: 3,
            maxZoom: 20,
            scaleControl: false,
            clickableIcons: false,
            mapTypeControl: true,
            zoomControl: true,
            fullscreenControl: false,
            streetViewControl: false,
            styles: brand.googlemaps.mapstyle,
        },
        autocomplete: {
            componentRestrictions: {
                country: 'ZA'
            },
            fields: ["address_components", "formatted_address", "geometry"]
        }
    });

    const handleMapLoad = (newMap) => {
        if (!mapRef || !mapRef.current) mapRef.current = newMap;
        // this.handleDataLayers();
        if (props.handleMapLoad) props.handleMapLoad();
        // addMapClickListener();
        let tmpOptions = googleMapOptions;
        tmpOptions.mapTypeControlOptions = {
            position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
            style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            mapTypeIds: [
                window.google.maps.MapTypeId.ROADMAP,
                window.google.maps.MapTypeId.HYBRID,
                window.google.maps.MapTypeId.TERRAIN,
                window.google.maps.MapTypeId.SATELLITE
            ]
        }
        tmpOptions.zoomControlOptions = {
            position: window.google.maps.ControlPosition.RIGHT_CENTER
        }
        setGoogleMapOptions(tmpOptions);

        geolocate();

        let defaultLayers = layers.filter(l => l.active)

        addWMSOverlay(defaultLayers)
        // InitialiseGoogleAutocomplete()
    }

    const geolocate = () => {
        setSearchingLocation(true);
        hidePopup();
        Geolocation.getUserLocation((pos) => {
            if (!markerRef || !markerRef.current) handleMarker(new window.google.maps.LatLng(pos.lat, pos.lng));
            handleMarker(new window.google.maps.LatLng(pos.lat, pos.lng))
            goToPosition(new window.google.maps.LatLng(pos.lat, pos.lng));
            reverseGeocoding(pos.lat, pos.lng)

        }, (err) => {
            setDisableGeolocation(true);
            console.error('Geolocation Error: ', err)
        });
    }


    useEffect(() => {
        console.log('isMobile: ', settings.isMobile)
        assignLayerIndexes();
    }, [])


    useEffect(() => {
        // console.log('********************place', place)
    }, [place])


    const assignLayerIndexes = () => {
        let allLayers = layers;
        for (let i = 0; i < allLayers.length; i++) {
            allLayers[i].layer_index = i;
        }
        setLayers(allLayers)
    }

    const reverseGeocoding = (latitude, longitude) => {
        if (!window.google) throw "Google Maps not initialised";
        if (!googleGeocoderRef || !googleGeocoderRef.current) googleGeocoderRef.current = new window.google.maps.Geocoder();
        googleGeocoderRef.current.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
            setSearchingLocation(false);
            if (status === "OK") {
                if (results[0]) { // take the first result
                    googlePlaceRef.current = results[0];
                    console.log(results[0])
                    setPlace(results[0]);
                    doCoverageCheck(latitude, longitude);
                } else {
                    window.alert("No results found");
                }
            } else {
                console.error("Geocoder failed due to: " + status);
            }
        });
    }

    const handleMapClick = (lat, lng) => {
        // updateMarkerAndPopup(new window.google.maps.LatLng(lat, lng));
        hidePopup();
        // handleMarker(new window.google.maps.LatLng(lat, lng))
        // goToPosition(new window.google.maps.LatLng(lat, lng))
        // doCoverageCheck(lat, lng); 
        handleMarker(new window.google.maps.LatLng(lat, lng))
        goToPosition(new window.google.maps.LatLng(lat, lng));
        reverseGeocoding(lat, lng)
    }

    const handleMarker = (pos, icon) => {
        if (!markerRef || !markerRef.current) {
            markerRef.current = new window.google.maps.Marker({
                position: pos,
                map: mapRef.current,
                optimized: false,
                draggable: true,
                icon: {
                    url: '/markerRed.svg',
                    scaledSize: new window.google.maps.Size(30, 37),
                    size: new window.google.maps.Size(30, 37),
                    anchor: new window.google.maps.Point(15, 37)
                },
            });
            if (!settings.isMobile) {
                markerRef.current.addListener('click', function () {
                    updatePopup()
                });
                markerRef.current.addListener('dragstart', function () {
                    // updatePopup()
                    hidePopup();
                });
                markerRef.current.addListener('dragend', function () {
                    reverseGeocoding(markerRef.current.position.lat(), markerRef.current.position.lng())
                    goToPosition(new window.google.maps.LatLng(markerRef.current.position.lat(), markerRef.current.position.lng()));
                });
            }
        } else {
            markerRef.current.setPosition(pos)
        }
        if (icon) markerRef.current.setIcon(icon)

        // goToPosition(pos)
        // handlePopup(pos, <DefaultMarkerContent layers={layers} />)
    }


    const hidePopup = () => {
        if (!popupRef || !popupRef.current) return;
        popupRef.current.setMap(null);
        popupRef.current.close()
    }

    const handlePopup = (position, content) => { 
        if (!mapRef || !mapRef.current) throw 'Google Map not initialised';
        if (!position) {
            if (!markerRef || !!markerRef.current)
                throw 'No or invalid position provided.'
            position = { lat: markerRef.current.position.lat(), lng: markerRef.current.position.lng() }
        };

        if (!popupRef || !popupRef.current) { 
            popupRef.current = new window.google.maps.InfoWindow({ 
                content: ReactDOMServer.renderToStaticMarkup(content), 
            });
        }
        // popupRef.current.setPosition(position)
        else if (content) { 
            popupRef.current.setContent(ReactDOMServer.renderToStaticMarkup(content));
            popupRef.current.setPosition(position)
        }
        // popupRef.current.setMap(mapRef.current);
        popupRef.current.open(mapRef.current, markerRef.current)

    }

    const updateMarkerAndPopup = (pos, icon) => {
        if (!markerRef || !markerRef.current) return;
        handleMarker(pos, icon);
        goToPosition(pos)
        updatePopup()
    }

    const updatePopup = () => {
        if(!markerRef || !markerRef.current) return;
        handlePopup(markerRef.current.position, <LayerProviderListContent layers={layers} coverage={coverageResultsRef.current} place={googlePlaceRef.current} />)
        hideLineLocation(layers)
        showLineLocation(layers.filter(l => l.active && l.showLocation))
    }

    //pan map to latlng
    const goToPosition = (pos) => {
        if (brand.googlemaps.markerZoomLevel) mapRef.current.setZoom(brand.googlemaps.markerZoomLevel);
        mapRef.current.panTo(pos);

        let topEdgeOffset;
        if (settings.pageHeight < 600) {
            topEdgeOffset = settings.pageHeight * 0.05;
        } else {
            topEdgeOffset = settings.pageHeight * 0.1;
        }
        if (settings.isMobile) {
            topEdgeOffset = settings.pageHeight * 0.25;
        }
        mapRef.current.panBy(0, -topEdgeOffset);

    }

    const onAddressChange = (place) => {
        console.log('place', place)
        googlePlaceRef.current = place;
        setPlace(place)
        if (place && place.geometry) {
            // updateMarkerAndPopup(new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng()))
            handleMarker(new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng()))
            goToPosition(new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng()));
            doCoverageCheck(place.geometry.location.lat(), place.geometry.location.lng());
        } else {
            let coords = validateCoords(place)
            if (coords) {
                handleMarker(new window.google.maps.LatLng(+coords[0], +coords[1]))
                goToPosition(new window.google.maps.LatLng(+coords[0], +coords[1]));
                reverseGeocoding(+coords[0], +coords[1])
            }
            // doCoverageCheck(coords[0], coords[1]);
            // updateMarkerAndPopup(new window.google.maps.LatLng(coords[0], coords[1]))
        }
    }

    const doCoverageCheck = async (latitude, longitude) => {

        coverageResultsRef.current = {}
        do28EastCoverageCheck(latitude, longitude)
        do28EastLinesCoverageCheck(latitude, longitude)
        do28EastTowersCoverageCheck(latitude, longitude)
        doMTNCoverageCheck(latitude, longitude)

    }

    const do28EastCoverageCheck = async (latitude, longitude) => {
        // console.log('coverage starting', coverageResultsRef.current)
        const coverageRequest = await fetch(`${process.env.REACT_APP_28EAST_API_URL}/getcoverage?key=${brand.coverageAPI.apikey}&services=&latitude=${latitude}&longitude=${longitude}`);

        if (!coverageRequest.ok) {
            throw "Oops, something went wrong: " + coverageRequest.status
        }
        let results = await coverageRequest.json();
        if(results) coverageResultsRef.current = Utils.deepMerge(coverageResultsRef.current, results)

        updatePopup();

    }

    const do28EastLinesCoverageCheck = async (latitude, longitude) => {
        // console.log('lines starting: ', coverageResultsRef.current)

        const coverageRequest = await fetch(`${process.env.REACT_APP_28EAST_API_URL}/getlines?key=${brand.coverageAPI.apikey}&services=&latitude=${latitude}&longitude=${longitude}`);

        if (!coverageRequest.ok) {
            throw "Oops, something went wrong: " + coverageRequest.status
        }
        let results = await coverageRequest.json();
        if(results) coverageResultsRef.current = Utils.deepMerge(coverageResultsRef.current, results)

        updatePopup();

    }

    const do28EastTowersCoverageCheck = async (latitude, longitude) => {

        // console.log('towers starting', coverageResultsRef.current)
        const coverageRequest = await fetch(`${process.env.REACT_APP_28EAST_API_URL}/gettowers?key=${brand.coverageAPI.apikey}&services=&latitude=${latitude}&longitude=${longitude}`);

        if (!coverageRequest.ok) {
            throw "Oops, something went wrong: " + coverageRequest.status
        }
        let results = await coverageRequest.json();
        if(results) coverageResultsRef.current = Utils.deepMerge(coverageResultsRef.current, results)

        updatePopup();

    }


    const doMTNCoverageCheck = async (latitude, longitude) => {
        if (!window.google) return;

        // console.log('towers starting', coverageResultsRef.current)
        let url = process.env.REACT_APP_MTN_API_URL
        let mapBounds = mapRef.current.getBounds();
        let mapBoundsMetersSW = Utils.reProject(
            mapBounds.getSouthWest().lat(),
            mapBounds.getSouthWest().lng()
        );
        let mapBoundsMetersNE = Utils.reProject(
            mapBounds.getNorthEast().lat(),
            mapBounds.getNorthEast().lng()
        );
        let bbox = `${mapBoundsMetersSW[0]},${mapBoundsMetersSW[1]},${mapBoundsMetersNE[0]},${mapBoundsMetersNE[1]}`;
        let pixelLocation = Utils.getPixelLocation(new window.google.maps.LatLng(latitude, longitude), mapRef.current);

        let SWPixelPoint = Utils.getPixelLocation(mapBounds.getSouthWest(), mapRef.current);
        let NEPixelPoint = Utils.getPixelLocation(mapBounds.getNorthEast(), mapRef.current);

        let width = SWPixelPoint.x + NEPixelPoint.x;
        let height = SWPixelPoint.y + NEPixelPoint.y;

        let params =
            'callback=mtnFeasibilityResponse' +
            '&format_options=callback%3AmtnFeasibilityResponse' + //calback method is needed by api
            '&service=WMS' +
            '&version=1.3.0' +
            '&request=GetFeatureInfo' +
            '&info_format=text%2Fjavascript' +
            '&exceptions=application%2Fvnd.ogc.se_xml' +
            '&layers=mtnsi%3AMTNSA-Coverage-FIXLTE-0' +
            '&query_layers=mtnsi%3AMTNSA-Coverage-FIXLTE-0' +
            '&feature_count=100' +
            // "&propertyName=CELL_ID%2CCENTERLAT%2CCENTERLONG%2CLTE_TYPE%2CFREQ%2CSIGNAL%2CSLOTS%2CDISPLAY_COV_MAP%2CLAST_UPDATE_DATE" +
            '&propertyName=' +
            '&srs=EPSG%3A900913' +
            '&width=' + width +
            '&height=' + height +
            '&bbox=' + bbox +
            '&i=' + pixelLocation.x.toFixed(0) +
            '&j=' + pixelLocation.y.toFixed(0);
 
        const coverageRequest = await fetchJsonp(`${url}?${params}`, {
            jsonpCallbackFunction: 'mtnFeasibilityResponse'
        });
        let results = await coverageRequest.json();
        let mtnResults = mtnFeasibilityResponse(results)
        
        if(mtnResults) coverageResultsRef.current = Utils.deepMerge(coverageResultsRef.current, mtnResults)

        updatePopup(); 
    }

    const mtnFeasibilityResponse = (response) => {
        // console.log('(!!!!!!!!!!!!!!!!!!!!!!!!!!!,',response)
                
        let results = response//await coverageRequest.json();

        let mtnResults = {
            success: true,
            message: 'ok',
            services: [],
        };

        // console.log('results.features',results.features)
        if (results && results.features && results.features.length > 0) {
            //determine feasibility
            mtnResults.services.push({
                type: 'lte',
                providers: [
                    {
                        provider: 'mtn',
                        status: 'live',
                    },
                ],
            });
        }
        return mtnResults;
        // coverageResultsRef.current = Utils.deepMerge(coverageResultsRef.current, mtnResults)
        // updatePopup();
    }

    const validateCoords = (value) => {
        if (!value) return false;
        let splitVal = value.split(',');
        if (!splitVal || splitVal.length < 2) splitVal = value.split(' ');
        if (!splitVal || splitVal.length < 2 || !splitVal[0] || !splitVal[1])
            return false;
        if (
            splitVal[0] > -90 &&
            splitVal[0] < 90 &&
            splitVal[1] > -180 &&
            splitVal[1] < 180
        )
            return splitVal;
        return false;
    }

    const handleLayerItemClick = (item) => {
        let newLayers = [...layers];
        hideLineLocation(newLayers.filter(l => !l.active))

        if (singleLayerSelect) {
            newLayers.forEach(layer => {
                layer.active = false;
                if (layer.layerName === item.layerName) layer.active = true;
            });
            if(!item.showLocation) addWMSOverlay([item])
            else showLineLocation([item])
        } else {

            let selectedLayers = newLayers.filter(l => l.layerName === item.layerName)
            if (!selectedLayers || selectedLayers.length === 0) return;
            selectedLayers[0].active = !selectedLayers[0].active;

            addWMSOverlay(newLayers.filter(l => l.active && !l.showLocation))
            
            showLineLocation(newLayers.filter(l => l.active && l.showLocation))
        }
        hideWMSOverlay(newLayers.filter(l => !l.active))
        // hideLineLocation(newLayers.filter(l => !l.active))
        setLayers(newLayers);
    }

    const addWMSOverlay = (layers, layerIndex) => {
        let displayLayers = []
        let externalWMSLayers = []
        let maxLayerIndex = layerIndex ? layerIndex : 0;
        let maxExternalLayerIndex = layerIndex ? layerIndex : 0;
        layers.forEach(layer => {
            if (layer.externalWMS) {
                externalWMSLayers.push(layer);
                maxExternalLayerIndex = layer.layer_index > maxExternalLayerIndex ? layer.layer_index : maxExternalLayerIndex
            }
            else {
                displayLayers.push(layer);
                maxLayerIndex = layer.layer_index > maxLayerIndex ? layer.layer_index : maxLayerIndex
            }
        })

        if (externalWMSLayers.length > 0) addExternalWMSOverlay(externalWMSLayers, layerIndex ? layerIndex : maxExternalLayerIndex)
        if (displayLayers.length > 0) add28EastWMSOverlay(displayLayers, layerIndex ? layerIndex : maxLayerIndex)
    }

    const add28EastWMSOverlay = (layers, layerIndex = 0) => {
        if (!mapRef || !mapRef.current) throw "Map not initialised";
        if (!layers) throw "Invalid layers defined"

        let displayLayers = "", displayStyles = "";

        layers.forEach(layer => {
            if (!layer.externalWMS) {
                if (layerIndex) {
                    displayLayers += "," + layer.layerName;
                    displayStyles += "," + layer.layerStyle;
                }
                mapRef.current.overlayMapTypes.setAt(layer.layer_index, WMSOverlay.GetGoogleMapTileOverlay(mapRef.current, brand.coverageAPI.apikey, layer.layerName, layer.layerStyle));
            }
        });
        if (layerIndex) {
            displayLayers = displayLayers.substring(1, displayLayers.length)
            displayStyles = displayStyles.substring(1, displayStyles.length)
            mapRef.current.overlayMapTypes.setAt(layerIndex, WMSOverlay.GetGoogleMapTileOverlay(mapRef.current, brand.coverageAPI.apikey, displayLayers, displayStyles));
        }
    }

    const addExternalWMSOverlay = (layers, layerIndex = 0) => {
        if (!mapRef || !mapRef.current) throw "Map not initialised";
        if (!layers) throw "Invalid layers defined"

        layers.forEach(layer => {
            if (layer.externalWMS)
                mapRef.current.overlayMapTypes.setAt(layerIndex ? layerIndex : layer.layer_index, WMSOverlay.GetGoogleMapWMSTileOverlay(mapRef.current, layer.externalWMSURL, null, layer.layerName, layer.layerStyle, layer.customSLD, { bboxm: 'meters' }));
        })
    }

    const hideWMSOverlay = (layers, layerIndex = 0) => {
        if (!mapRef || !mapRef.current) throw "Map not initialised";
        if (!layers) throw "Invalid layers defined"

        layers.forEach(layer => {
            mapRef.current.overlayMapTypes.setAt(layerIndex ? layerIndex : layer.layer_index, null);
        })
    }

    const showLineLocation = (layers, latitude, longitude) => {
        if(!layers) return;
        if(!coverageResultsRef.current) return

        if(!latitude || !longitude) {
            if(!markerRef.current) return

            latitude = markerRef.current.position.lat()
            longitude = markerRef.current.position.lng()
        }
        
        // if(googlePolylinesRef.current) hideLineLocation(layers)
        
        layers.forEach(layer => {
            if(layer.showLocation){
                for(let i in coverageResultsRef.current.services) { 
                    if (coverageResultsRef.current.services[i].type === layer.layerService && coverageResultsRef.current.services[i].providers.length > 0) { 
                        for(let j in coverageResultsRef.current.services[i].providers) { 
                            if (coverageResultsRef.current.services[i].providers[j].provider === layer.layerCoverageName) { 
                                if(coverageResultsRef.current.services[i].providers[j].los ){
                                    coverageResultsRef.current.services[i].providers[j].los.forEach(tower => {
                                        if(tower.haslineofsight){
                                            googlePolylinesRef.current.push(DrawLine.drawLine(mapRef.current, new window.google.maps.LatLng(latitude, longitude), new window.google.maps.LatLng(tower.tower.location.lat, tower.tower.location.lng), layer.layerCoverageName + '-icon'))
                                        }
                                    })
                                }
                            }
                        };
                    }
                };
            }
        })

    }

    const hideLineLocation = (layers) => {
        if (!mapRef || !mapRef.current) throw "Map not initialised";
        if (!layers) throw "Invalid layers defined"
        if(!googlePolylinesRef.current || googlePolylinesRef.current.length === 0) return 
  
        googlePolylinesRef.current = DrawLine.clearLines(googlePolylinesRef.current); 
    }

    return (
        <div className={brand.navbar.show && brand.fullHeight ? classes.rootNav : classes.root}>
            <div className={classes.userControls}>
                <GoogleAutocompleteWidget
                    noLoad={true}
                    id='pac-input'
                    placeholder='Enter a address or XY (latitude,longitude)'
                    disableGeolocation={disableGeolocation}
                    handleAddressChange={onAddressChange}
                    autocomplete={googleMapOptions.autocomplete}
                    handleGeolocate={geolocate}
                    loadingLocation={searchingLocation}
                    place={place}
                />
                <div className={classes.layerControls}>
                    <OverlayLayerSelector
                        key={'overlay-selector'}
                        toggleTitle={'Filter coverage layer'}
                        // displayToggle={false}
                        layers={layers}
                        handleLayerItemClick={handleLayerItemClick}
                        singleSelect={singleLayerSelect}
                        displayToggle
                        layerLegend
                        useSubheadings
                    />
                </div>
            </div>
            <GoogleMap
                className={classes.googleMaps}
                apikey={brand.googlemaps.apikey}
                options={googleMapOptions}
                handleMapClick={handleMapClick}
                onMapLoad={handleMapLoad}
                autocompleteInput={'pac-input'}
                onAddressChange={onAddressChange}
                addMapClickListener={!settings.isMobile}
            />

        </div>
    );
}
