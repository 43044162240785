import {SET_SETTINGS} from './types';

export const reducer = (state, action) => {
    switch (action.type) {
      case SET_SETTINGS:
        return [
          ...state,
          action.settings
        ];
      default:
        return state;
    }
  };
  