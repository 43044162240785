import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";
import GreyMapStyle from '../../mapstyles/grey.mapstyle'
import DarkMapStyle from '../../mapstyles/dark.mapstyle'
import AssasinscreedMapStyle from '../../mapstyles/assasinscreed.mapstyle'
import DefaultMapStyle from '../../mapstyles/default.mapstyle'

export const BrandContext = createContext();

const BrandProvider = ({ children }) => {
    const [brand, dispatchBrand] = useReducer(reducer, {
        clientName: 'Stem',
        logo: "./stem-logo-white.svg",
        title: "",
        coverageAPI: {
            apikey: 'AIzaSyB-9-xrezSp78zAUDFX7RG4yo_EwoInDoY',
        },
        googlemaps: {
            apikey: 'AIzaSyDFXFwoemUVjWajj0OdAH25mmPUqHUYeMU',
            mapstyle: GreyMapStyle.style,
            center: { lat: -26, lng: 25 }, //{ lat: -26, lng: 25 }
            markerZoomLevel: 16, //can be omitted
        },
        firebaseConfig: {
            apiKey: "AIzaSyCaZDeD1BMKIVDTB4Z9VByGRR3N80ljkpw",
            authDomain: "east-skywire.firebaseapp.com",
            databaseURL: "https://east-skywire.firebaseio.com",
            projectId: "east-skywire",
            storageBucket: "east-skywire.appspot.com",
            messagingSenderId: "327940271685",
            appId: "1:327940271685:web:03744d91599b5dd37afd2c"
        },
        color: {
            primary: {
                light: '#515860',
                main: '#227478',
                dark: '#072344',
                contrastText: '#fff',
            },
            secondary: {
                light: '#9ed8a4',
                main: '#7BC583',
                dark: '#5b9e62',
                contrastText: '#fff',
            },
            default: { 
                light: '#aaadaf',
                main: '#343a40',
                dark: '#30353a',
                contrastText: '#fff',
            }
        },
        wms: {
            singleLayer: true,
            color: { 
            },
        },
        navbar: {
            show: false,
            position: 'fixed' //fixed, float
        },
        sidebar: {
            show: false
        },
        fullHeight: true,
        useLogin: false, 
        callToActions: [
            {
                name: 'Sign-Up',
                url: 'http://stem.co.za/dynamic-products',
                variant: "outlined",
                color: "primary",
                shouldHaveProvider: true
            },
            {
                name: 'Contact Us',
                url: 'http://stem.co.za/contact',
                variant: "outlined",
                color: "default"
            }
        ],
        css: {}
    });

    return (
        <BrandContext.Provider value={{ brand, dispatchBrand }}>
            {children}
        </BrandContext.Provider>
    );
};

export default BrandProvider;
