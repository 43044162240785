import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BrandContext } from '../context/BrandContext/context';
import { SiteContext } from '../context/SiteContext/context';
import GoogleAutocompleteWidgetPlain from '../components/GoogleAutocomplete/GoogleAutocompleteWidgetPlain';
import Geolocation from '../services/Geolocation';
import Utils from '../utils/Utils';
import { Box, Button, Grid, IconButton, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { LocationDisabled, LocationSearching, MyLocation } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: '10pt'
        // height: '100%'
    },
    rootNav: {
        display: 'flex',
        // height: '100%'
    },
    googleAutocomplete: {
        maxWidth: '500px',
        width: '100%'
    },
    textField: {
        width: '100%'
    },
    right: {
        float: 'right',
        margin: '5pt'
    },
    callToAction: {
        paddingTop: '10pt',
        paddingBottom: '10pt',
    },
    inlineLoader: {
        width: '1em !important',
        height: '1em !important',
        marginRight: '6px'
    },
    loader: {
        backgroundColor: 'rgb(232, 244, 253)',//theme.palette.secondary.light,
        padding: '10pt',
        borderRadius: '2px'
    }
}));


export default function CoverageCheckerContainer(props) {
    const { brand } = useContext(BrandContext)
    const { settings } = useContext(SiteContext)
    const classes = useStyles();

    const [disableGeolocation, setDisableGeolocation] = useState(false);
    const [searchingLocation, setSearchingLocation] = useState(true);
    const [subpremise, setSubpremise] = useState('');

    const [streetNumber, setStreetNumber] = useState('');
    const [suburb, setSuburb] = useState('');
    const [streetName, setStreetName] = useState('');
    const [city, setCity] = useState('');
    const [province, setProvince] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [premise, setPremise] = useState('');
    const [placeDetails, setPlaceDetails] = useState()
    const [loading, setLoading] = useState(false)

    const [coverageResults, setCoverageResults] = useState({})
    const googleGeocoderRef = useRef(); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
    const coverageResultsRef = useRef({}); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
    const googlePlaceRef = useRef(); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
    const [place, setPlace] = React.useState();
    const [googleOptions, setGoogleOptions] = React.useState({
        autocomplete: {
            componentRestrictions: {
                country: ['ZAF', 'GBR']
            },
            fields: ["address_components", "formatted_address", "geometry"]
        }
    });

    useEffect(() => {
        // setCoverageResults(coverageResultsRef.current)
        console.log('updated')
    }, [coverageResults])

    const onAddressChange = (place) => {
        console.log('place', place)
        googlePlaceRef.current = place;
        setPlace(place)
        let details = parseAddressComponents(place)
        if (place && place.geometry) {
            doCoverageCheck(place.geometry.location.lat(), place.geometry.location.lng(), details.postalCode);
        } else {
            let coords = validateCoords(place)
            if (coords) {
                reverseGeocoding(+coords[0], +coords[1])
            } else {
                coverageResultsRef.current = {}
                setCoverageResults({})
            }
        }
    }

    const parseAddressComponents = (place) => {
        let newPlaceDetails = {
            streetNumber: getAddressComponent(place, 'street_number'),
            suburb: getAddressComponent(place, 'sublocality', 'sublocality_level_1', 'sublocality_level_2', 'sublocality_level_3', 'sublocality_level_4', 'sublocality_level_5'),
            streetName: getAddressComponent(place, 'route'),
            city: getAddressComponent(place, 'locality'),
            province: getAddressComponent(place, 'administrative_area_level_1'),
            postalCode: getAddressComponent(place, 'postal_code'),
            premise: getAddressComponent(place, 'premise'),
            subpremise: getAddressComponent(place, 'subpremise'),
        }
        setPlaceDetails(newPlaceDetails)
        setStreetName(newPlaceDetails.streetName)
        setSuburb(newPlaceDetails.suburb)
        setStreetNumber(newPlaceDetails.streetNumber)
        setCity(newPlaceDetails.city)
        setProvince(newPlaceDetails.province)
        setPostalCode(newPlaceDetails.postalCode)
        setPremise(newPlaceDetails.premise)
        setSubpremise(newPlaceDetails.subpremise)
        return newPlaceDetails
    }

    //get address type from Google response
    const getAddressComponent = (place, component1, component2, component3, component4, component5, component6) => {
        if (!place || !place.address_components) return '';
        for (let i = 0; i < place.address_components.length; i++) {
            let base = place.address_components[i].types.indexOf(component1) > -1
            let one = place.address_components[i].types.indexOf(component2) > -1
            let two = place.address_components[i].types.indexOf(component3) > -1
            let three = place.address_components[i].types.indexOf(component4) > -1
            let four = place.address_components[i].types.indexOf(component5) > -1
            let five = place.address_components[i].types.indexOf(component6) > -1
            if (base || one || two || three || four || five) {
                let result = place.address_components[i].long_name.length > 31 ? place.address_components[i].long_name.substring(0, 31) : place.address_components[i].long_name
                return result.replace(/&/g, '').replace(/=/g, '')
            }
        }
        return 'NA';
    }

    const validateCoords = (value) => {
        if (!value) return false;
        let splitVal = value.split(',');
        if (!splitVal || splitVal.length < 2) splitVal = value.split(' ');
        if (!splitVal || splitVal.length < 2 || !splitVal[0] || !splitVal[1])
            return false;
        if (
            splitVal[0] > -90 &&
            splitVal[0] < 90 &&
            splitVal[1] > -180 &&
            splitVal[1] < 180
        )
            return splitVal;
        return false;
    }

    const geolocate = () => {
        setSearchingLocation(true);
        Geolocation.getUserLocation((pos) => {
            reverseGeocoding(pos.lat, pos.lng)

        }, (err) => {
            setDisableGeolocation(true);
            console.error('Geolocation Error: ', err)
        });
    }

    const reverseGeocoding = (latitude, longitude) => {
        if (!window.google) throw "Google Maps not initialised";
        if (!googleGeocoderRef || !googleGeocoderRef.current) googleGeocoderRef.current = new window.google.maps.Geocoder();
        googleGeocoderRef.current.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
            setSearchingLocation(false);
            if (status === "OK") {
                if (results[0]) { // take the first result
                    googlePlaceRef.current = results[0];
                    console.log(results[0])
                    setPlace(results[0]);
                    parseAddressComponents(results[0])
                    let details = parseAddressComponents(place)
                    doCoverageCheck(latitude, longitude, details.postalCode);
                } else {
                    window.alert("No results found");
                }
            } else {
                console.error("Geocoder failed due to: " + status);
                parseAddressComponents(null)
                coverageResultsRef.current = { success: true, services: [] }
                setCoverageResults({ success: true, services: [] })
            }
        });
    }

    const doCoverageCheck = async (latitude, longitude, postcode) => {

        setLoading(true)
        coverageResultsRef.current = {}
        setCoverageResults({})
        await Promise.all([
            // doCityFibreCoverageCheck(postcode),
            do28EastCoverageCheck(latitude, longitude),
            do28EastLinesCoverageCheck(latitude, longitude),
            do28EastTowersCoverageCheck(latitude, longitude),
            // doMTNCoverageCheck(latitude, longitude)
        ])

        updateFeedback();
    }

    const do28EastCoverageCheck = async (latitude, longitude) => {
        // console.log('coverage starting', coverageResultsRef.current)
        const coverageRequest = await fetch(`${process.env.REACT_APP_28EAST_API_URL}/getcoverage?key=${brand.coverageAPI.apikey}&services=&latitude=${latitude}&longitude=${longitude}`);

        if (!coverageRequest.ok) {
            throw "Oops, something went wrong: " + coverageRequest.status
        }
        let results = await coverageRequest.json();
        // if (results) coverageResultsRef.current = Utils.deepMerge(coverageResultsRef.current, results)
        if (results) {
            coverageResultsRef.current = Utils.deepMerge(coverageResultsRef.current, results)
            // setCoverageResults(coverageResultsRef.current)
        }
        console.log('coverageResults', coverageResults)
        console.log('coverageResultsRef.current', coverageResultsRef.current)

        // updateFeedback();
    }

    const updateFeedback = () => {
        setCoverageResults(coverageResultsRef.current)
        setLoading(false)
    }

    const do28EastLinesCoverageCheck = async (latitude, longitude) => {
        // console.log('lines starting: ', coverageResultsRef.current)

        const coverageRequest = await fetch(`${process.env.REACT_APP_28EAST_API_URL}/getlines?key=${brand.coverageAPI.apikey}&services=&latitude=${latitude}&longitude=${longitude}`);

        if (!coverageRequest.ok) {
            throw "Oops, something went wrong: " + coverageRequest.status
        }
        let results = await coverageRequest.json();
        if (results) {
            coverageResultsRef.current = Utils.deepMerge(coverageResultsRef.current, results)
            // setCoverageResults(coverageResultsRef.current)
        }
        console.log('coverageResults', coverageResults)
        console.log('coverageResultsRef.current', coverageResultsRef.current)

        // updateFeedback();
    }

    const do28EastTowersCoverageCheck = async (latitude, longitude) => {

        // console.log('towers starting', coverageResultsRef.current)
        const coverageRequest = await fetch(`${process.env.REACT_APP_28EAST_API_URL}/gettowers?key=${brand.coverageAPI.apikey}&services=&latitude=${latitude}&longitude=${longitude}`);

        if (!coverageRequest.ok) {
            throw "Oops, something went wrong: " + coverageRequest.status
        }
        let results = await coverageRequest.json();
        // if (results) coverageResultsRef.current = Utils.deepMerge(coverageResultsRef.current, results)
        if (results) {
            coverageResultsRef.current = Utils.deepMerge(coverageResultsRef.current, results)
            // setCoverageResults(coverageResultsRef.current)
        }
        console.log('coverageResults', coverageResults)
        console.log('coverageResultsRef.current', coverageResultsRef.current)

        // updateFeedback();
    }

    const doCityFibreCoverageCheck = async (postcode) => {
        try {
            let cityFibreRequest = await fetch(`${process.env.REACT_APP_CityFibre_API_URL}?key=${brand.coverageAPI.apikey}&postcode=${postcode}`)
            if (!cityFibreRequest.ok) {
                throw "Oops, something went wrong: " + cityFibreRequest.status
            }
            let results = await cityFibreRequest.json();
            // if (results) coverageResultsRef.current = Utils.deepMerge(coverageResultsRef.current, results)
            if (results) {
                coverageResultsRef.current = Utils.deepMerge(coverageResultsRef.current, results)
                // setCoverageResults(coverageResultsRef.current)
            }
            console.log('coverageResults', coverageResults)
            console.log('coverageResultsRef.current', coverageResultsRef.current)
        } catch (err) {
            console.log(err)
            return {
                success: false,
                error: err.message,
                code: err.code
            }
        }
    }

    const handleChangeSubPremise = (e) => {
        setSubpremise(e.target.value)
        let t = placeDetails;
        t.subpremise = subpremise
        setPlaceDetails(t)
        console.log('t', t)
    }

    const handleChangeStreetNumber = (e) => {
        setStreetNumber(e.target.value)
        let t = placeDetails;
        t.streetNumber = streetNumber
        setPlaceDetails(t)
        console.log('t', t)
    }
    const handleChangeStreetName = (e) => {
        setStreetName(e.target.value)
        let t = placeDetails;
        t.streetName = streetName
        setPlaceDetails(t)
        console.log('t', t)
    }
    const handleChangePremise = (e) => {
        setPremise(e.target.value)
        let t = placeDetails;
        t.premise = premise
        setPlaceDetails(t)
        console.log('t', t)
    }
    const handleChangeSuburb = (e) => {
        setSuburb(e.target.value)
        let t = placeDetails;
        t.suburb = suburb
        setPlaceDetails(t)
        console.log('t', t)
    }
    const handleChangeCity = (e) => {
        setCity(e.target.value)
        let t = placeDetails;
        t.city = city
        setPlaceDetails(t)
        console.log('t', t)
    }
    const handlePostalCodeChange = (e) => {
        setPostalCode(e.target.value)
        let t = placeDetails;
        t.postalCode = postalCode
        setPlaceDetails(t)
        console.log('t', t)
    }

    const handleCallToAction = cto => {
        console.log(cto)


        let statuses = ''
        let fnos = ''

        if (coverageResults.services) {
            for (let serviceIndex in coverageResults.services) {
                for (let providerIndex in coverageResults.services[serviceIndex].providers) {
                    if (fnos !== '') {
                        fnos += ','
                        statuses += ','
                    }
                    statuses += coverageResults.services[serviceIndex].providers[providerIndex].status
                    fnos += coverageResults.services[serviceIndex].providers[providerIndex].provider
                }
            }
        }
        console.log(coverageResults)

        let url = cto.url +
            `?address=${place ? place.formatted_address : ''}&` +
            `latitude=${place ? place.geometry.location.lat : ''}&` +
            `longitude=${place ? place.geometry.location.lng : ''}&` +
            `province=${place ? placeDetails.province : ''}&` +
            `town=${place ? placeDetails.city : ''}&` +
            `suburb=${place ? placeDetails.suburb : ''}&` +
            `street=${place ? placeDetails.streetName : ''}&` +
            `streetnumber=${place ? placeDetails.streetNumber : ''}&` +
            `postalcode=${place ? placeDetails.postalCode : ''}&` +
            `premise=${place ? placeDetails.premise : ''}&` +
            `subpremise=${place ? placeDetails.subpremise : ''}&` +
            `status=${statuses}&` +
            `fno=${fnos}`

        window.location = url;

    }

    return (
        <div className={brand.navbar.show && brand.fullHeight ? classes.rootNav : classes.root}>
            <Grid container spacing={1}>
                <Grid item xs={12} >
                    <div className={classes.googleAutocomplete}>
                        <GoogleAutocompleteWidgetPlain
                            // noLoad={true}
                            className={classes.googleAutocomplete}
                            id='pac-input'
                            placeholder='Enter a address or XY (latitude,longitude)'
                            disableGeolocation={disableGeolocation}
                            handleAddressChange={onAddressChange}
                            autocomplete={googleOptions.autocomplete}
                            handleGeolocate={geolocate}
                            loadingLocation={searchingLocation}
                            // place={place}
                            apikey={brand.googlemaps.apikey}
                        />
                    </div>

                    {loading &&
                        <Box className={classes.loader} ><CircularProgress className={classes.inlineLoader} /> Checking your feasibility</Box>
                    }

                    {(coverageResults && coverageResults.services && coverageResults.services.length > 0) ?
                        <Alert severity="success">Congratulations! You have coverage</Alert>
                        : (coverageResults && coverageResults.services && coverageResults.services.length === 0) ?
                            <Alert severity="warning">You unfortunately do not have coverage </Alert>
                            : <></>
                    }
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <TextField className={classes.textField} id="standard-required" label="No / Unit No" value={subpremise} disabled={!placeDetails} onChange={handleChangeSubPremise} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField className={classes.textField} required id="standard-required" label="Street number" value={streetNumber} disabled={!placeDetails} onChange={handleChangeStreetNumber} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField className={classes.textField} required id="standard-required" label="Street name" value={streetName} disabled={!placeDetails} onChange={handleChangeStreetName} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField className={classes.textField} id="standard-required" label="Building name or complex" value={premise} disabled={!placeDetails} onChange={handleChangePremise} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField className={classes.textField} required id="standard-required" label="Suburb" value={suburb} disabled={!placeDetails} onChange={handleChangeSuburb} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField className={classes.textField} required id="standard-required" label="City" value={city} disabled={!placeDetails} onChange={handleChangeCity} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField className={classes.textField} required id="standard-required" label="Postcode" value={postalCode} disabled={!placeDetails} onChange={handlePostalCodeChange} />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    {(brand.callToActions) &&
                        brand.callToActions.map(cto => {
                            return (
                                (cto.shouldHaveProvider && coverageResults && coverageResults.services) ?
                                    <Button className={classes.right} variant={cto.variant ? cto.variant : "outlined"} color={cto.color} onClick={() => { handleCallToAction(cto) }}>
                                        {cto.name}
                                    </Button>
                                    : !cto.shouldHaveProvider &&
                                    <Button className={classes.right} variant={cto.variant ? cto.variant : "outlined"} color={cto.color} onClick={() => { handleCallToAction(cto) }}>
                                        {cto.name}
                                    </Button>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </div>
    );
}