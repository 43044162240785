import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, GridList, GridListTile, GridListTileBar, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import { CheckCircleOutline, HighlightOff } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '250px',
    },
    headerRoot: {
        width: '100%',
        color: '#424242',
        fontSize: '10pt'
    },
    headerLine1: {
        fontWeight: "bold"
    },
    contentRoot: {
        width: '100%',
    },
    listItem: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.03)',
        padding: '3pt'
    },
    list: {
        listStyleType: 'none',
        padding: '2px'
    },
    hasCoverage: {
        color: '#777777',
        float: 'right'
    },
    noCoverage: {
        color: '#777777',
        float: 'right'
    }
}));

function Header(props) {
    const classes = useStyles();
    return (
        <div className={classes.headerRoot}>
            {props.children ? props.children :
                <>
                    {
                        (props.place && props.place.address_components) &&
                        <>
                            <Typography className={classes.headerLine1}>
                                {props.place.address_components[0] &&
                                    props.place.address_components[0].long_name + " "
                                }
                                {props.place.address_components[1] &&
                                    props.place.address_components[1].long_name
                                }
                            </Typography>
                            <Typography className={classes.headerLine2}>
                                {props.place.address_components[2] &&
                                    props.place.address_components[2].long_name
                                }
                            </Typography>
                            <Typography className={classes.headerLine3}>
                                {props.place.address_components[3] &&
                                    props.place.address_components[3].long_name
                                }
                            </Typography>
                        </>
                    }
                </>}
        </div>
    );
}

function Content(props) {
    const classes = useStyles();

    return (
        <div className={classes.contentRoot}>
            <ul className={classes.list}>
                {props.layers &&
                    props.layers.map(layer => {
                        return (
                            layer.display &&
                            <li className={classes.listItem} key={`infowindow-listitem-${layer.layerService}`}>
                                <Typography variant='body1'>
                                    {layer.title}
                                    {
                                        availableCoverage(layer, props.coverage) ?
                                            <CheckCircleOutline className={classes.hasCoverage} />
                                            :
                                            <HighlightOff className={classes.noCoverage} />
                                    }
                                </Typography>
                            </li>

                        );
                    })
                }
            </ul>
        </div>
    );
}

function availableCoverage(layer, coverage) {
    if (!layer || !coverage || !coverage.success) return false;

    for (let i in coverage.services) {
        if (coverage.services[i].type === layer.layerService && coverage.services[i].providers.length > 0) {
            for (let j in coverage.services[i].providers) { 
                if ((Array.isArray(layer.layerCoverageName) && layer.layerCoverageName.some(l => l === coverage.services[i].providers[j].provider)) 
                    || (coverage.services[i].providers[j].provider === layer.layerCoverageName)) {
                    return true
                }
            };
        }
    };

    return false
}


export default function LayerProviderListContent(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Header place={props.place} />
            <Divider />
            <Content layers={props.layers} coverage={props.coverage} />
        </div>
    );
}