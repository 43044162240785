 import {
    Map, AddAlert, AllInclusive
} from "@material-ui/icons";
import CoverageCheckerContainer from "../containers/CoverageCheckerContainer";
import CoverageMapContainer from "../containers/CoverageMapContainer";
 

const Routes = [
    // {
    //     path: "/",
    //     name: "Coverage Map",
    //     icon: Map,
    //     component: CoverageMapContainer
    // },
    {
        path: "/",
        name: "Coverage Map",
        icon: Map,
        component: CoverageCheckerContainer
    }
];

export default Routes;