import '../modules/Proj4Module';
const Utils = {}

Utils.deepMerge = (...objects) => {
    const isObject = (obj) => obj && typeof obj === 'object';

    function deepMergeInner(target, source) {
        Object.keys(source).forEach((key) => {
            const targetValue = target[key];
            const sourceValue = source[key];

            if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
                target[key] = targetValue.concat(sourceValue);
                // let t = targetValue.map((item, i) => Object.assign({}, item, sourceValue[i]));
                // target[key] = targetValue.map((item, i) => Object.assign({}, item, sourceValue[i])); 
                // target[key] = [targetValue, sourceValue].reduce((a, b) => a.map((c, i) => Object.assign({}, c, b[i])));
                // let t = [targetValue, sourceValue].map((a, b) => deepMergeInner(a,b));
                // target[key] = t[0].concat(t[1]);
            } else if (isObject(targetValue) && isObject(sourceValue)) {
                target[key] = deepMergeInner(Object.assign({}, targetValue), sourceValue);
            } else {
                target[key] = sourceValue;
            }
        });

        return target;
    }

    if (objects.length < 2) {
        throw new Error('deepMerge: this function expects at least 2 objects to be provided');
    }
 
    if (objects.some(object => !isObject(object))) {
        throw new Error('deepMerge: all values should be of type "object"');
    }

    const target = objects.shift();
    let source;
 

    while (source = objects.shift()) {
        deepMergeInner(target, source);
    }

    return target;
}


Utils.reProject = (lat, lng) => {
    if (!window.proj4) throw "proj4 not initialised";

    return window.proj4
        ? window.proj4('EPSG:4326', 'EPSG:900913', [lng, lat])
        : [lng, lat];
}


Utils.getPixelLocation = (latlng, googleMap) => {
    if(!window.google) throw 'Google Maps not initialised'
    if(!googleMap) throw 'Google Maps object is required'
    
    var scale = Math.pow(2, googleMap.getZoom());
    // The NorthWest corner of the current viewport corresponds
    // to the upper left corner of the map.
    // The script translates the coordinates of the map's center point
    // to screen coordinates. Then it subtracts the coordinates of the
    // coordinates of the map's upper left corner to translate the
    // currentLatLng location into pixel values in the <div> element that hosts the map.
    var nw = new window.google.maps.LatLng(
        googleMap.getBounds().getNorthEast().lat(),
        googleMap.getBounds().getSouthWest().lng()
    );
    // Convert the nw location from geo-coordinates to screen coordinates
    var worldCoordinateNW = googleMap.getProjection().fromLatLngToPoint(nw);
    // Convert the location that was clicked to screen coordinates also
    var worldCoordinate = googleMap.getProjection().fromLatLngToPoint(latlng);
    var pixelLocation = new window.google.maps.Point(
        Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
        Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
    );
    return pixelLocation;
}

export default Utils