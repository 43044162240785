import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { BrandContext } from '../../context/BrandContext/context'
import { Button, Menu, MenuItem } from '@material-ui/core';
import firebase from 'firebase'


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    title: {
        flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,

    avatar: {
        margin: 10,
    },
    logo: {
        position: "relative",
        padding: "0px",
        paddingRight: "10px",
        zIndex: "4",
        "&:after": {
            content: '""',
            position: "absolute",
            bottom: "0",
            height: "1px",
            right: "15px",
            width: "calc(100% - 60px)",
        }
    },
    logoLink: {
        textTransform: "uppercase",
        padding: "5px 0",
        display: "block",
        fontSize: "16px",
        textAlign: "left",
        fontWeight: "400",
        lineHeight: "30px",
        textDecoration: "none",
        backgroundColor: "transparent"
    },
    logoImageContainer: {
        height: "50px",
        display: "flex",
        maxHeight: "50px",
        marginLeft: "5px",
        marginRight: "5px"
    },
    logoImage: {
        height: "50px"
    },
}));


export default function Navbar(props) {
    const { brand } = useContext(BrandContext)
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [user, setUser] = useState()
    const [anchorEl, setAnchorEl] = useState()

    useEffect(() => {
        setUser(firebase.auth().currentUser)
    }, [])

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        firebase.auth().signOut();
        if(props.handleSignOut) props.handleSignOut();
    }

    const handleLogin = () => {
        if(props.handleLogin) props.handleLogin();
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar)}>
                <Toolbar className={classes.toolbar}>
                    <div className={classes.logo}>
                        <a
                            href="/"
                            className={classes.logoLink}
                        >
                            <div className={classes.logoImageContainer}>
                                <img src={brand.logo} alt="" className={classes.logoImage} />
                            </div>
                        </a>
                    </div>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        {brand.title}
                    </Typography>

                    <span className={classes.toolbarButtons}>
                        {user && user.email && (
                            <div >
                                <Button  color="inherit" aria-controls="profile-menu" aria-haspopup="true" onClick={handleClick} tile='Click to sign out'>
                                    <p >{user.email}</p>
                                </Button>
                                <Menu 
                                    id="profile-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleSignOut} tile='Click to sign out'>Sign out</MenuItem>
                                </Menu>

                            </div>
                        ) 
                            // : (
                            //     <Button color="inherit" edge="end" onClick={handleLogin}>Login</Button>
                            // )
                        }
                        {/* <Button color="inherit" edge="end" >Login</Button> */}
                    </span>
                </Toolbar>
            </AppBar>
        </div>
    );
}