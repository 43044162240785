import './App.css';
import PortalView from './views/PortalView';
import history from 'history/browser';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BrandContext } from './context/BrandContext/context'
import { useContext, useEffect, useRef, useState } from 'react';
import { SiteContext } from './context/SiteContext/context';
import firebase from 'firebase/app';
import 'firebase/auth';
import LoginView from './views/LoginView';


const providers = {
  emailProvider: new firebase.auth.EmailAuthProvider(),
};

function App() {
  const { brand } = useContext(BrandContext)
  const [busyLogin, setBusyLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const firebaseApp = !firebase.apps.length ? firebase.initializeApp(brand.firebaseConfig) : firebase.app();//firebase.initializeApp(brand.firebaseConfig);
  const firebaseAppAuth = firebaseApp.auth();
  // const firebaseApp = useRef()//firebase.initializeApp(brand.firebaseConfig);
  // const firebaseAppAuth = useRef() //= firebaseApp.auth();

  const theme = createMuiTheme({
    Sidebar: {
      color: 'black',
      textDecoration: 'none !important'
    },
    SidebarItem: {
      textDecoration: 'none !important'
    },
    BrandContainer: {
      textAlign: 'center',
    },
    palette: {
      primary: brand.color.primary,
      secondary: brand.color.secondary,
      default: brand.color.default,
      live: brand.wms && brand.wms.color && brand.wms.color.live ? brand.wms.color.live : brand.color.primary,
      comingsoon: brand.wms && brand.wms.color && brand.wms.color.comingsoon ? brand.wms.color.comingsoon : brand.color.secondary
    }
  });


  useEffect(() => {
    // handleSignOut();
    if (brand.useLogin) {
      firebaseAppAuth.onAuthStateChanged(function (firebaseUser) {
        setBusyLogin(false)
        setLoading(false)
        setUser(firebaseUser)
      });
    }
  }, [])


  const handleSignOut = () => {
    if(brand.useLogin)
      firebaseAppAuth.signOut();
  };

  const onLogin = (email, password) => {
    setLoading(true)
    setLoginErrorMessage('');
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(function (error) {
        let message = error.message;
        if (
          error.code === 'auth/user-not-found' ||
          error.code === 'auth/wrong-password'
        )
          message = 'Invalid email or password provided.';

        setLoading(false)
        setLoginErrorMessage(message)
      });
  };

  return (
    <MuiThemeProvider theme={theme}>
      {(brand.useLogin && user) || !brand.useLogin ?
        <Router history={history}>
          <Switch>
            <Route path='/' theme={theme} component={PortalView} key="PortalView" />
          </Switch>
        </Router>
        :
        <LoginView
          loading={loading}
          onLogin={onLogin}
          message={loginErrorMessage}
        />
      }
    </MuiThemeProvider>
  );
}

export default App;

