import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import BrandProvider from './context/BrandContext/context';
import SiteProvider from './context/SiteContext/context';
import Analytics from './services/Analytics';

ReactDOM.render(
  <React.StrictMode>
    <BrandProvider>
      <SiteProvider>
        <App />
      </SiteProvider>
    </BrandProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(Analytics.Log);