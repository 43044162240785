import React, { useContext } from 'react'; 
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline'; 
import Container from '@material-ui/core/Container'; 
import { Switch, Route } from "react-router-dom";
import { SiteContext } from '../../context/SiteContext/context'; 
import { BrandContext } from '../../context/BrandContext/context';


export default function Content() {
    const { settings } = useContext(SiteContext)
    const { brand } = useContext(BrandContext)

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            width: '100%'
        },
        toolbar: {
            paddingRight: 24, // keep right padding when drawer closed
        }, 
        appBarSpacer: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            maxWidth: '100%'
        },
        fullHeightContainer: {
            padding: theme.spacing(0), 
            maxWidth: '100%',
            height: brand.navbar.show && brand.navbar.position === 'fixed' ? 'calc(100% - 64px)' : '100%',
        },
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
        }
    }));

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <div className={brand.navbar.show && brand.navbar.position === 'fixed' ? classes.appBarSpacer: ''} />
                <Container className={brand.fullHeight ? classes.fullHeightContainer : classes.container}>
                    <Switch>
                        {settings.routes.map((prop, key) => {
                            return (
                                <Route
                                    path={prop.path}
                                    component={prop.component}
                                    key={key}
                                    exact={true}
                                />
                            );
                        })}
                    </Switch>
                </Container>
            </main>
        </div>
    );
}