const ReactDOMServer = require('react-dom/server');
class GoogleDefaultInfoWindow { 
    constructor(position, content, options) { 
        if(!window.google) throw 'Google Maps not initialised';

        this.infowindow = new window.google.maps.InfoWindow({
            ...options,
            content: ReactDOMServer.renderToStaticMarkup(content),
            // content: content
        });
        if(position) this.setPosition(position)
    }

    setMap(map) {
        this.map = map;
    }

    setPosition(position){
        this.infowindow.setPosition(position)
    }
 
    setContent(content){
        // const ReactDOMServer = require('react-dom/server');
        this.infowindow.setContent(ReactDOMServer.renderToStaticMarkup(content))
        // this.infowindow.setContent(content)
    }
    /** Called when the popup is added to the map. */
    open(map, marker) {
        if(!marker) return;
        if(!map) return
        this.marker = marker;
        this.map = map
        this.infowindow.open(this.map, marker);  
        // else this.infowindow.open(this.map);  
    }
     /** Called when the popup is added to the map. */
    close() {
        this.infowindow.open(null);  
    }  
}

// const GoogleDefaultInfoWindow = {}
// let infowindow = null;
// let marker = null;
// let map = null;

// GoogleDefaultInfoWindow.init = (position, content, options) => { 
//         if(!window.google) throw 'Google Maps not initialised';

//         infowindow = new window.google.maps.InfoWindow({
//             ...options,
//             content: ReactDOMServer.renderToString(content),
//             // content: content
//         });
//         if(position) this.setPosition(position) 
        
// }

// GoogleDefaultInfoWindow.setPosition = (position) => {
//     if(infowindow)
//         infowindow.setPosition(position)
// }

// GoogleDefaultInfoWindow.setContent = (content) => { 
//     if(infowindow)
//         infowindow.setContent(ReactDOMServer.renderToString(content)) 
// }
// /** Called when the popup is added to the map. */
// GoogleDefaultInfoWindow.open = (markerObj) => {
//     marker = markerObj;
//     if(marker) infowindow.open(this.map, marker);  
//     else infowindow.open(map);  
// }
//  /** Called when the popup is added to the map. */
//  GoogleDefaultInfoWindow.close = () => {
//     if(infowindow)
//         infowindow.open(null);  
// }  

// GoogleDefaultInfoWindow.setMap = (mapObj) => {
//     map = mapObj;
// }

export default GoogleDefaultInfoWindow; 