import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {
    LocationOn,
    MyLocation,
    Search,
    LocationSearching,
    LocationDisabled,
} from '@material-ui/icons';
import { Grid, TextField, Typography } from '@material-ui/core';
import { throttle } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import useAutocomplete from '@material-ui/lab/useAutocomplete';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        // minWidth: '300px',
        maxWidt: '500px'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));


function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GoogleAutocompleteWidgetPlain(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(props.place);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [sessionToken, setSessionToken] = React.useState('');
    const [useAutocomplete, setUseAutocomplete] = React.useState(true);
    // const [locationLoadingTimer, setLocationLoadingTimer] = React.useState();
    const inputValueRef = React.useRef(props.place ? props.place.formatted_address : '');
    const locationLoadingTimerRef = React.useRef(false);
    const loadingLocationOnRef = React.useRef(false);
    // const [loadingLocationOn, setLoadingLocationOn] = React.useState(false);
    const loaded = React.useRef(false);

    if (!props.noLoad && typeof window !== 'undefined' && !window.google && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${props.apikey}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    } else if (!props.noLoad) {
        loaded.current = true;
    }

    useEffect(() => {
        if (props.place) { 
            setUseAutocomplete(false) 
            let newPlace = {
                // formatted_address: props.place.formatted_address,
                description: props.place.formatted_address,
                matched_substrings: props.place.address_components,
                place_id: props.place.place_id,  
                types: props.place.types
            }
            // setInputValue(props.place.formatted_address)
            // setOptions([newPlace])
            // setValue(props.place)
        }
    })
 
    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    const updateSessionToken = () => {
        if (!window.google) return;
        if (!sessionToken || sessionToken === '') {
            setSessionToken(new window.google.maps.places.AutocompleteSessionToken())
        }
    }

    React.useEffect(() => {
        // if(!useAutocomplete) return;

        let active = true;
        updateSessionToken();
        if (!sessionToken || sessionToken === '') return
        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }
        let request = { input: inputValue, sessionToken: sessionToken }
        if (props.autocomplete && props.autocomplete) {
            Object.keys(props.autocomplete).forEach(key => {
                request[key] = props.autocomplete[key]
            });
        }

        fetch(request, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                    console.log('*** newOption', value)
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                    console.log('*** results', results)
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    const handleAddressChange = (address) => {
        if (address && address.place_id) getPlaceDetails(address)
        else if (props.handleAddressChange) props.handleAddressChange(address);
        setSessionToken('');
        // setUseAutocomplete(true);
    }

    const getPlaceDetails = (address) => {
        let service = new window.google.maps.places.PlacesService(document.createElement('div'));
        try {
            service.getDetails({
                placeId: address.place_id,
                sessionToken: sessionToken,
                fields: props.autocomplete && props.autocomplete.fields ? props.autocomplete.fields : ['address_component', 'formatted_address', 'geometry'] //default value if no fields are set
            }, (place, status) => {
                if (props.handleAddressChange) props.handleAddressChange(place);
            });
        } catch (err) {
            console.log(err)
        }
    }

    const handleGeolocate = (event) => {
        if (props.handleGeolocate) props.handleGeolocate();
    }

    return (
        <div className={classes.root}>
            <Autocomplete
                id={props.id ? props.id : 'pac-input'}
                className={classes.input}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.description ? option.description : option.formatted_address ? option.formatted_address : '')}
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                // defaultValue={props.place}
                freeSolo 
                onChange={(event, newValue) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                    handleAddressChange(newValue);
                }}
                onInputChange={(event, newInputValue) => { 
                    setInputValue(newInputValue);  
                }}
                renderInput={(params) => (
                    <TextField 
                        {...params} 
                        value={inputValue}
                        placeholder={props.placeholder} 
                        fullWidth
                        onKeyDown={e => {
                            if ((e.key === 'Enter')&& e.target.value) {
                                e.preventDefault(); 
                            }
                          }} />
                )}
                renderOption={(option) => {
                    let matches;
                    let parts;

                    if(option && option.structured_formatting){
                        matches = option.structured_formatting.main_text_matched_substrings;
                        parts = parse(
                            option.structured_formatting.main_text,
                            matches.map((match) => [match.offset, match.offset + match.length]),
                        ); 
                    } else if(option && option.formatted_address){
                        matches = option.formatted_address;
                        parts = option.formatted_address;
                    }

                    return (
                        <Grid container alignItems="center">
                            <Grid item>
                                <LocationOn className={classes.icon} />
                            </Grid>
                            <Grid item xs>
                                {parts ? parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text ? part.text : part}
                                    </span>
                                )):
                                <span key={option} style={{ fontWeight: 700}}>
                                    {option}
                                </span>
                                }

                                <Typography variant="body2" color="textSecondary">
                                    {option.structured_formatting? option.structured_formatting.secondary_text : option}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                }}
            />


            <IconButton 
                type="submit" 
                className={classes.iconButton} 
                aria-label="search" 
                onClick={(e) => {
                    e.preventDefault();
                    handleAddressChange(inputValue); 
                }}>
                <Search />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton className={classes.iconButton} aria-label="geolocation" onClick={handleGeolocate}>
                {
                    !props.loadingLocation ?
                        (props.disableGeolocation ? <LocationDisabled /> : <LocationSearching />)
                        : (
                            !loadingLocationOnRef.current ? <LocationSearching /> : <MyLocation />
                        )
                }
            </IconButton>
        </div>

    );
}
