import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { SiteContext } from '../../context/SiteContext/context'
import { BrandContext } from '../../context/BrandContext/context'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from "@material-ui/core/Icon";
import { NavLink } from "react-router-dom";
import Typography from '@material-ui/core/Typography';


const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    toggle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        borderTop: '1px solid rgba(0,0,0,0.1)'
    },
    active: {
        textDecoration: 'none',
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.secondary.dark,
        "&,&:hover": {
            textDecoration: 'none',
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.secondary.dark,
        }
    },
    Sidebar: {
        textDecoration: 'none',
        ...theme.Sidebar,
    },
    routes: {
        height: 'calc(100% - 120px)', 
        overflowX: 'hidden',
        overflowY: 'auto'
    },

    logo: {
        position: "relative",
        padding: "0px",
        paddingRight: "10px",
        zIndex: "4",
        "&:after": {
            content: '""',
            position: "absolute",
            bottom: "0",
            height: "1px",
            right: "15px",
            width: "calc(100% - 60px)",
        }
    },
    logoLink: {
        textTransform: "uppercase",
        padding: "5px 0",
        display: "block",
        fontSize: "16px",
        textAlign: "left",
        fontWeight: "400",
        lineHeight: "30px",
        textDecoration: "none",
        backgroundColor: "transparent"
    },
    logoImageContainer: { 
        maxHeight: "50px", 
        padding: '10pt'
    },
    logoImage: {
        width: "50px"
    },
    BrandContainer: { 
        ...theme.brandContainer
    }
}));


function Sidebar(props) {
    const classes = useStyles();
    const { brand } = useContext(BrandContext)
    const { settings } = useContext(SiteContext)
    const [open, setOpen] = React.useState(true);

    const handleDrawer = () => {
        setOpen(!open);
    };

    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        return window.location.pathname === routeName;
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                {brand.navbar.show ? <div className={classes.appBarSpacer} /> 
                : <div className={classes.brandContainer} >
                        {(!brand.navbar.show && brand.logo)&&  
                            <div className={classes.logoImageContainer}>
                                <img src={brand.logo} alt="" className={classes.logoImage} />
                            </div> 
                        }
                        {(!brand.navbar.show && brand.title)&&  
                            <Typography component="h1" variant="h6" color="inherit" className={classes.title}>
                                {brand.title}
                            </Typography> 
                        }
                        <Divider />
                    </div>
                }
                <div className={classes.routes}>
                    <List>
                        {(settings && settings.routes && settings.routes.length > 0) &&
                            settings.routes.map((prop, key) => {
                                return (
                                    <NavLink
                                        // to={!activeRoute( prop.path) ?  prop.path : '?#'}
                                        to={prop.path}
                                        className={activeRoute(prop.path) ? classes.active : classes.Sidebar}
                                        activeClassName="active"
                                        key={key}
                                        title={key}
                                    >
                                        <ListItem button key={prop.name} className={activeRoute(prop.path) ? classes.active : ''} >
                                            <ListItemIcon>
                                                {typeof prop.icon === "string" ? (
                                                    <Icon className={activeRoute(prop.path) ? classes.active : ''}>
                                                        {prop.icon}
                                                    </Icon>
                                                ) : (
                                                        <prop.icon
                                                            className={activeRoute(prop.path) ? classes.active : ''}
                                                        />
                                                    )}
                                            </ListItemIcon>
                                            <ListItemText primary={prop.name} />
                                        </ListItem>
                                    </NavLink>
                                );
                            })
                        }</List>
                </div>
                <div className={classes.toggle}>
                    <IconButton onClick={handleDrawer}>
                        {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
            </Drawer>
        </div>
    );
}

export default Sidebar;