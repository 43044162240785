import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    }, 
    mapStyle: {
        width: '100%',
        height: '100%',
    }
}));

export default function GoogleMap(props) {
    const classes = useStyles(); 
    const googleMapRef = useRef(null);
    const [map, setMap] = useState(null)
    const [autocomplete, setAutocomplete] = useState(null)

    useEffect(() => {   
        if (!window.google) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://maps.google.com/maps/api/js?libraries=places&key=${props.apikey}`;
            script.id = 'google-maps';
            document.body.appendChild(script);

            script.addEventListener('load', e => {
                onScriptLoad()
            })
            if(props.addMapClickListener)
                script.addEventListener('click', e => {
                    if(props.onClick) props.onClick(e.latLng.lat(), e.latLng.lng());
                }) 
        }  
    });

    const onScriptLoad = () => { 
        let options = {}
        if(props.options && props.options.map) options = props.options.map; 
        options.mapTypeControlOptions = {
            position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
            style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            mapTypeIds: [
                window.google.maps.MapTypeId.ROADMAP,
                window.google.maps.MapTypeId.HYBRID,
                window.google.maps.MapTypeId.TERRAIN,
                window.google.maps.MapTypeId.SATELLITE
            ]
        }
        options.zoomControlOptions = {
            position: window.google.maps.ControlPosition.RIGHT_CENTER
        }

        let newMap = new window.google.maps.Map(googleMapRef.current, options)
        if(props.handleMapClick){
            window.google.maps.event.addListener(newMap, 'click', function (event) { //drop pin when user clicks on map    
                if(props.handleMapClick){
                    props.handleMapClick(event.latLng.lat(), event.latLng.lng());
                }
            });
        }  

        setMap(newMap);
        if(props.onMapLoad) props.onMapLoad(newMap) 

    } 
    
    return (
        <div id="google-map-container" 
            className={classes.mapStyle}
            ref={googleMapRef}>
        </div>
    );
}
